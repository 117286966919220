import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/navbar/navbar';

import '../css/FAQ.css';

function VeotsOTP() {

    return (
        <div className='customer-FAQ'>
            <Navbar></Navbar>
            <div className='FAQ-content' >                
                <div className='FAQ-header'>
                    <Link to="#" className='back-button'> &lt;</Link>
                    <div className='FAQ-title'>FAQ</div>
                </div>
                <div className='FAQ-body'>

                    <div className='ques-ans'>
                        <div className='ques'>
                        Q. How does veots help in identifying genuine/fake products?
                        </div>
                        <div className='ans'>
                        A. Customer can identify the genuineness of a product by scanning the pre-sale 
                        code provided by veots, which is available on the product 
                        </div>
                    </div>

                    <div className='ques-ans'>
                        <div className='ques'>
                        Q. How to locate the pre-sale code?
                        </div>
                        <div className='ans'>
                        A. The pre-sale code is usually located somewhere on the packaging material of the 
                        product that is easily accessible for the customer, even before purchasing/opening 
                        the package. 
                        <br></br>The custmer needs to look out for the text "scan here to verify" on the packaging.
                        </div>
                    </div>

                    <div className='ques-ans'>
                        <div className='ques'>
                        Q. Is a specific app needed to scan the Pre-sale code?
                        </div>
                        <div className='ans'>
                        A. No, it can be scanned using any QR code scanner or a native camera that is capable of scanning a QR code.
                        </div>
                    </div>

                    <div className='ques-ans'>
                        <div className='ques'>
                        Q. What should the customer do after purchasing the product that has veots protection?
                        </div>
                        <div className='ans'>
                        A. The customer would have to again scan the pres-sale code followed by post-sale code and claim the ownership of the product 
                        </div>
                    </div>

                    <div className='ques-ans'>
                        <div className='ques'>
                        Q. What is a post sale code? 
                        </div>
                        <div className='ans'>
                        A. Post sale code is the second QR code that is usually hidden inside the packaging and is accessible to the user only after a purchase is made 
                        </div>
                    </div>

                    <div className='ques-ans'>
                        <div className='ques'>
                        Q. What is need for scanning the post sale code?
                        </div>
                        <div className='ans'>
                        A. Upon scanning the pre-sale code followed by post-sale code, the customer can claim the product. Once the claim is made, all the other products with same pre-sale/post-sale codes are digitally marked as fake. 
                        </div>
                    </div>

                    <div className='ques-ans'>
                        <div className='ques'>
                        Q. What does the customer get by scanning the post sale code? 
                        </div>
                        <div className='ans'>
                        Upon scanning the pre-sale code followed by post sale code, the customer can claim the product and in addition gets veots credit points or can activate warranty if provided by the manufacturer.
                        </div>
                    </div>

                    <div className='ques-ans'>
                        <div className='ques'>
                        Q. What can the customer do with the credit points? 
                        </div>
                        <div className='ans'>
                        A. Credit points can be redeemed in the form of cash back 
                        </div>
                    </div>

                    <div className='ques-ans'>
                        <div className='ques'>
                        Q. How to redeem veots credits? 
                        </div>
                        <div className='ans'>
                        A. The customer would have to open a veots account from the login page or the sign up screen that appears after claiming the product. Customer would have to associate the account with mobile number/email that is provided while claiming the product. The user has to provide the upi id into which the cash back needs to be credited. The path to provide the upi id from the login page is...... 
                        </div>
                    </div>

                    <div className='ques-ans'>
                        <div className='ques'>
                        Q. How to contact veots in case of disputes?
                        </div>
                        <div className='ans'>
                        A. Please write to us on info@veots.com, we’ll get back to your at the earliest
                        </div>
                    </div>

                    <div className='ques-ans'>
                        <div className='ques'>

                        </div>
                        <div className='ans'>

                        </div>
                    </div>

                </div>       
            </div>
        </div>
    )
}

export default VeotsOTP


