import './App.css';
import {BrowserRouter, Route, Routes} from "react-router";

import Claim from './routes/claim-product'
import SignInup from './routes/signIn-signUp'
import CustomerLogin from './routes/customer-login'
import Register from './routes/register'
import LandingPage from './routes/landingPage'
import EditProfile from './routes/edit-profile'
import RestPassword from './routes/reset-password'
import VeotsCredit from './routes/veots-credits'
import VeotsOTP from './routes/veots-OTP'
import FAQ from './routes/FAQ'
import QRScanned from "./routes/QRScanned";
import Mypurchase from './routes/my-purchase';
import HomePage from './routes/homePage';
import PageNotFound from "./routes/pageNotFound";
import Message from './routes/message';
import Popup from './components/popups/renderPopup';

function App() {
  return (
    <div className="App">

      <Routes>
        {/* <Route path="/" element={<HomePage/>} /> */}
        {/* <Route path="/customer/infomessage" element={<Message/>}/>
        <Route path="/" element={<CustomerLogin/>}/> */}
        <Route path="/verify/:qrid" element={<QRScanned />}/>
        {/* <Route path="/customer/claim-product" element={<Claim/>}/>
        <Route path="/customer/signin/signup" element={<SignInup/>}/> */}
        
        {/* <Route path="/customer/signup" element={<Register/>}/>
        <Route path="/customer/landing-page" element={<LandingPage/>}/>
        <Route path="/customer/edit-profile" element={<EditProfile/>}/>
        <Route path="/customer/reset-password" element={<RestPassword/>}/>
        <Route path="/customer/veotsCredits" element={<VeotsCredit/>}/>
        <Route path="/customer/verify-otp" element={<VeotsOTP/>}/>
        <Route path="/customer/FAQ" element={<FAQ/>}/>
        <Route path="/customer/MyPurchase" element={<Mypurchase/>}/> */}
        <Route path="*" element={<PageNotFound/>}/>

        {/* <Route path="/customer/popup" element={<Popup/>}/> */}
      </Routes>




      {/* <Fake/> */}
      {/* <InvalidProduct/> */}
      {/* <FinalAuthenic/> */}
      {/* <Scan1stQr/> */}
      {/* <ScannedProductImage/> */}
      {/* <LoadingPage/> */}
      {/* <Expired/> */}
      {/* <FirstQRScannedPage/> */}
    </div>
  );
}

export default App;
