import React from 'react';
import "../css/result.css"

import newVeotsLogo from "../assets/images/Veots_logo.png"
import newGooglePlay from '../assets/images/newGooglePlay.png'
import newAppStore from '../assets/images/newAppStore.png'

export default function Result() {
    const playStoreLink = "https://play.google.com/store/apps/details?id=com.Veots"
    const appStoreLink = "https://apps.apple.com/in/app/veots/id6448496034"

        return (
            <div className="qrs-main-body-container">
                <div className="qrs-img-heading">
                    <img src={newVeotsLogo} alt="" />
                </div>
                
                <div className="qrs-app-links-container">
                    <h2>Available On</h2>

                    <div className="qrs-app-links-wrapper">
                        <a href={playStoreLink} ><img className="bn45" src={newGooglePlay} alt="Google Play"/></a>
                        <a href={appStoreLink} ><img className="bn46" src={newAppStore} alt="App Store"/></a>
                    </div>
                </div>
            </div>
    )
}