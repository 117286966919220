import React from 'react'

function PageNotFound() {
    return (
        <div>
            sorry page not found
        </div>
    )
}

export default PageNotFound